import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const TaskDetails = ({ token }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [toggled, setToggled] = useState(false);
    const [taskList, setTaskList] = useState([]);

    useEffect(() => {
        if (!location.state) {
            history.push("/staff")
        } else {
            setTaskList(location.state.taskList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const collect = () => {
        mySwal.fire({
            title: t("Confirmation"),
            text: t("Are you sure the customer have collected the laundry?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("No"),
            confirmButtonText: t("Yes"),
            customClass: {
                confirmButton: 'collect-yes',
                cancelButton: 'collect-no'
            },
        })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    axios
                        .post("/api/user/dng/collected", { id: taskList[0].pack_number, role: "staff", token })
                        .then(res => {
                            history.push("/staff")
                        })
                        .catch(err => {
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                }
            })
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <div className="mb-3 d-flex" onClick={e => history.push('/staff/dashboard')}>
                        <i className="bi bi-chevron-left"></i>
                        <p className="ps-2">{t("Back")}</p>
                    </div>
                    {
                        taskList.length > 0 ? (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{t("Pack Number")} {taskList[0].pack_number}</h5>
                                </div>

                                <div className="card-body">
                                    <p>{t("Order Date")}: {taskList[0].date}</p>
                                    <p>{t("Outlet")}: {taskList[0].outlet_fullname} ({taskList[0].outlet_name})</p>
                                    <p>{t("Size")}: {taskList[0].size}</p>
                                    {taskList[0].collect_time ? <p>{t("Collect Time")}: {taskList[0].collect_time}</p> : null}
                                    {taskList[0].member_id ? <p>{t("Member ID")}: {taskList[0].member_id}</p> : null}
                                    {taskList[0].username ? <p>{t("Name")}: {taskList[0].username}</p> : null}
                                    {taskList[0].email ? <p>{t("Email")}: {taskList[0].email}</p> : null}
                                    {taskList[0].number ? <p>{t("Phone Number")}: {taskList[0].number}</p> : null}
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        taskList.map((task, i) => {
                            return (
                                <div className="card" key={i}>
                                    <div className="card-header">
                                        <h5 className="card-title">{t(task.name)}</h5>
                                    </div>

                                    <div className="card-body">
                                        <p>{t("Task ID")}: {task.id}</p>
                                        {task.capacity ? <p>{t("Capacity")}: {task.capacity}</p> : null}
                                        {task.mode ? <p>{t("Mode")}: {t(task.mode)}</p> : null}
                                        {task.machine_duration ? <p>{t("Duration")}: {task.machine_duration} {t("min")}</p> : null}
                                        {task.machine ? <p>{t("Machine")}: {task.machine}</p> : null}
                                        {task.status ? <p style={{ color: task.status === "Completed" ? "green" : "#FF3C00" }}>{t("Status")}: {t(task.status)}</p> : null}

                                        {task.status !== "Completed" ? (
                                            <div className='text-center'>
                                                <button className="btn btn-primary" type="button" onClick={() => history.push('/staff/task/start', { task })}>{t("Start Task")}</button>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        taskList.length > 0 && !taskList[0].collect_time && taskList.filter(task => task.status !== "Completed").length <= 0 ? (
                            <div className='text-center'>
                                <button className="btn btn-primary" type="button" onClick={() => collect()}>{t("Collected")}</button>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        token: state.staff.token,
    };
};

export default connect(mapStateToProps, null)(TaskDetails); 
