import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import Loading from '../../admin/components/Loading';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { currency, dateFormat } from '../../../dynamicController';
import { Tabs, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);

const Dashboard = ({ token, history, staff, language }) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState(staff.cash ? "Pending Payment" : "Pending");
    const [allPackNumber, setAllPackNumber] = useState([]);
    const [toggled, setToggled] = useState(false);
    const [allTask, setAllTask] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabsItem, setTabsItem] = useState([]);
    const [searchText, setSearchText] = useState("")
    const [date, setDate] = useState(dateFormat(new Date()))

    useEffect(() => {
        if (status === "Pending Payment") {
            fetchPaymentList(searchText, date, "Pending")
        } else if (status === "Complete Payment") {
            fetchPaymentList(searchText, date, "Success")
        } else {
            fetchTasks(status, searchText, date);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, searchText, date]);

    useEffect(() => {
        let list = [
            {
                key: "Pending",
                label: t("Task"),
            },
            {
                key: "Completed",
                label: t("Completed"),
            },
            {
                key: "Collected",
                label: t("Collected"),
            }
        ]

        if (staff.cash) {
            list.unshift({
                key: "Complete Payment",
                label: t("Print Receipt"),
            })
            list.unshift({
                key: "Pending Payment",
                label: t("Pending Payment (Cash)"),
            })
        }
        setTabsItem(list)
    }, [language])

    const fetchTasks = (status, searchText, date) => {
        setLoading(true)
        axios.post('/api/staff/getTask', { status, searchText, date }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                let allNumber = []
                for (let i = 0; i < res.data.data.length; i++) {
                    if (allNumber.filter(num => num.pack_number === res.data.data[i].pack_number).length <= 0) {
                        allNumber.push({
                            status: res.data.data[i].status === "Completed" ? "In Progress" : "Pending",
                            pack_number: res.data.data[i].pack_number
                        })
                    }
                }

                setAllPackNumber(allNumber);
                setAllTask(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const fetchPaymentList = (searchText, date, paymentStatus) => {
        setLoading(true)
        axios.post('/api/staff/getPaymentList', { searchText, date, status: paymentStatus }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                setPaymentList(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const cashPayment = (id, amount) => {
        mySwal.fire({
            title: t("Confirmation"),
            text: `${t("You have received the cash payment")} (${amount})?`,
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("No"),
            confirmButtonText: t("Yes"),
            customClass: {
                confirmButton: 'cash-payment-yes',
                cancelButton: 'cash-payment-no'
            },
        })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    setLoading(true)
                    axios.post('/api/staff/cashPayment', { id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            fetchPaymentList(searchText, date, "Pending")
                            setLoading(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error");
                        });
                }
            })
    }

    const printReceipt = (id) => {
        mySwal.fire({
            title: t("Confirmation"),
            text: t("Are you sure you want to print receipt?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("No"),
            confirmButtonText: t("Yes"),
            customClass: {
                confirmButton: 'print-receipt-yes',
                cancelButton: 'print-receipt-no'
            },
        })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    setLoading(true)
                    axios.post('/api/staff/printer/print/receipt', { transactionId: id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            fetchPaymentList(searchText, date, "Success")
                            setLoading(false)
                            mySwal.fire(t("Success"), t("Printer added print task successfully!"), "success");
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error");
                        });
                }
            })
    }

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const selectPack = (packNum) => {
        const thisPackTask = allTask.filter(t => t.pack_number === packNum)
        history.push('/staff/task/details', { taskList: thisPackTask })
    }

    const onSearch = (value) => {
        if (value) {
            setSearchText(value)
        } else {
            setSearchText("")
        }
    }

    const handleDate = (date, dateString) => {
        if (date) {
            setDate(dateString)
        } else {
            setDate("")
        }
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <h3 className='mt-3' style={{ display: 'flex', justifyContent: 'center' }}>{t("Drop And Go Portal")}</h3>

                    <div className="mt-4">
                        <DatePicker
                            id="date"
                            name="date"
                            placeholder={t("Select Date")}
                            value={date ? dayjs(date) : ""}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div className="mt-3">
                        <Input.Search
                            placeholder={t("Member ID / Email / Number")}
                            allowClear
                            onSearch={onSearch}
                            className="ant-design-search-input"
                        />
                    </div>

                    <div className="mt-3">
                        <Tabs
                            defaultActiveKey={status}
                            onChange={(key) => setStatus(key)}
                            items={tabsItem}
                        />
                    </div>

                    <div>
                        {
                            loading
                                ? <Loading />
                                : (
                                    <div className='d-flex flex-wrap justify-content-around'>
                                        {
                                            status === "Pending Payment" || status === "Complete Payment" ? (
                                                paymentList.map((list, i) => {
                                                    return (
                                                        <div key={i}
                                                            // style={{ padding: '20px', border: '2px solid grey', borderRadius: '10px', marginTop: '30px', background: "white", color: "black" }}
                                                            style={{ width: "90%", padding: '20px', borderLeft: status === "Complete Payment" ? '5px solid green' : '5px solid red', borderRadius: '5px', marginTop: '10px', background: "white", color: "black" }}
                                                            onClick={() => status === "Complete Payment" ? printReceipt(list.id) : cashPayment(list.id, `${currency(staff.country)} ${list.payment_amount}`)}
                                                        >
                                                            <p className="m-0" style={{ fontSize: "large" }}><b>{t("Total")}: {currency(staff.country)} {list.payment_amount}</b></p>
                                                            <p className="m-0">{t("Transaction ID")}: {list.id}</p>
                                                            <p className="m-0">{t("Order Date")}: {list.date}</p>
                                                            {list.member_id ? <p className="m-0 pt-3">{t("Member ID")}: {list.member_id}</p> : null}
                                                            {list.name ? <p className="m-0">{t("Name")}: {list.name}</p> : null}
                                                            {list.email ? <p className="m-0">{t("Email")}: {list.email}</p> : null}
                                                            {list.number ? <p className="m-0">{t("Phone Number")}: {list.number}</p> : null}
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                allPackNumber.map((number, i) => {
                                                    return (
                                                        <div key={i}
                                                            // style={{ padding: '20px', border: status === "Completed" || status === "Collected" ? '2px solid green' : number.status === "In Progress" ? '2px solid #FF3C00' : '2px solid grey', borderRadius: '10px', marginTop: '30px', background: "white", color: status === "Completed" || status === "Collected" ? "green" : number.status === "In Progress" ? "#FF3C00" : "black" }}
                                                            style={{ width: "90%", padding: '20px', borderRadius: '5px', borderLeft: status === "Completed" ? '5px solid green' : status === "Collected" ? '5px solid grey' : number.status === "In Progress" ? '5px solid #FF3C00' : '5px solid #1677ff', marginTop: '10px', background: "white", color: status === "Completed" ? "green" : status === "Collected" ? "grey" : number.status === "In Progress" ? "#FF3C00" : "#1677ff" }}
                                                            onClick={() => selectPack(number.pack_number)}
                                                        >
                                                            <h4>{t("Pack Number")}: {number.pack_number}</h4>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        staff: state.staff.staff,
        token: state.staff.token,
        language: state.user.language,
    };
};

export default connect(mapStateToProps, null)(Dashboard); 
